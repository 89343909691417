import { IoMdCloseCircle } from "react-icons/io";
import vinPlateImage from '../../../../assets/images/vinPlate.png';

const ModalVinNumber = ({ show, onClose }) => {
  if (!show) {
      return null;
  }

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col bg-white outline-none focus:outline-none">
              <div className="mb-[20px] w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                    <span className="text-xl text-ag-secondary font-bold">
                        How To Know My VIN Number
                    </span>
                </div>
                <div className="w-[5%]">
                    <button
                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                        onClick={onClose}
                    >
                        <IoMdCloseCircle />
                    </button>
                </div>
              </div>

              <div className="flex text-ag-secondary flex-col md:flex-row">
                <img className="w-[450px]" src={vinPlateImage} alt="VIN plate"/>

                <div className="md:px-[60px] max-w-[50ch]">
                  <div className="pt-[60px] text-[18px]">
                    <p>
                        You can find your VIN number in vehicle identification/information plate.
                    </p>

                    <p className="mt-[20px]">
                        Remember that your number has 17 characters.
                    </p>
                  </div>

                  <div className="text-center">
                    <button
                      onClick={onClose}
                      className="mt-[40px] font-bold bg-ag-primary-light text-white px-[50px] py-[5px] rounded-xl disabled:bg-zinc-200"
                      >
                        Go Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
    </>
  )
}

export default ModalVinNumber;