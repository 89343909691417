import { IoMdCloseCircle } from "react-icons/io";
import { useEffect, useState } from "react";

const ModalFeatures = ({ vinValue, arrFeatures, onClose, onAccept, showModal }) => {
    const [features, setFeatures] = useState([]);
    const [vinStr, setVinStr] = useState("");

    const handleChangeVIN = (evt) => {
        if (String(evt.target.value).match(/^[a-z0-9]*$/i) === null) return;
        setVinStr(evt.target.value)
    }

    const handleChange = (featureValue) => {
        const newFeatures = features.map(element => {
            if (element.value === featureValue.value) return {...element, status: !element.status}
            return element;
        });
        setFeatures(newFeatures);
    }

    const handleSubmit = () => {
        const foundFeatures = features.filter(element => element.status);
        onAccept(foundFeatures, vinStr);
    }

    useEffect(() => {
        setFeatures(arrFeatures);
    }, [arrFeatures]);

    useEffect(() => {
        if (vinValue !== "") setVinStr(vinValue);
    }, [vinValue]);

    if (!showModal) {
        return null;
    }

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[800px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        {/* Finish Quote */}
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="pb-2 pt-3">
                                <span className="pl-0 md:pl-5 text-ag-secondary-letter text-2xl font-semibold">
                                    Let's just get some specifics about your vehicle
                                </span>
                            </div>
                            <div className="m-0 md:m-2 lg:m-5 md:border md:border-ag-secondary rounded-xl">
                                <div className="px-5 pb-2 pt-3 lg:pt-8">
                                    <span className="text-ag-secondary-letter text-sm font-semibold">
                                        Select all the features that your vehicle is equipped with.
                                    </span>
                                </div>
                                <div className="w-full md:w-[80%] lg:w-full grid gap-y-5 md:grid-cols-2 lg:grid-cols-3 m-auto px-5 pb-2 lg:pb-8 pt-5 space-y-3 md:space-y-0">
                                    {
                                        features.map((element, i) => (
                                            <div className="flex justify-start items-center space-x-3" key={i}>
                                                <input
                                                    type="checkbox"
                                                    className="w-7 h-7 border border-zinc-500 rounded-lg checked:bg-ag-primary-light checked:border-ag-primary-light appearance-none"
                                                    checked={element.status}
                                                    onChange={() => handleChange(element)}
                                                />
                                                <span className="text-ag-secondary-letter text-xs">{ element.label }</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="px-5">
                                <div>
                                    <span className="text-ag-secondary-letter font-semibold">Please type your VIN # to confirm options selected</span>
                                    <span className="text-ag-secondary-letter">{' (optional)'}</span>
                                </div>
                                <div className="pt-3 text-center">
                                    <div className="flex justify-center items-center space-x-2">
                                        <label className="text-base text-ag-secondary-letter">VIN</label>
                                        <label className="text-xs text-ag-secondary-letter">{'('}Vehicle Identification Number{'):'}</label>
                                    </div>
                                    <input
                                        className="w-full text-center text-ag-secondary-letter border border-gray-500 pl-1 pr-3 py-2 rounded-xl disabled:bg-zinc-200 truncate"
                                        type="text"
                                        name="vin"
                                        placeholder="VIN"
                                        value={vinStr}
                                        onChange={handleChangeVIN}
                                        maxLength={17}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end items-center space-x-5 pt-3">
                                <button
                                    type="button"
                                    className="px-10 md:px-20 py-3 bg-ag-primary-light text-white text-xs rounded-xl"
                                    onClick={() => onClose(false)}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="px-10 md:px-20 py-3 bg-green-600 text-white text-xs rounded-xl"
                                    onClick={() => handleSubmit()}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default ModalFeatures;