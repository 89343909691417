import React from 'react'

const PrivacyNoticeInfo = () => {
    return (
        <>
            <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                <div className="text-center">
                    <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">Avondale Auto Glass Privacy Policy</h1>
                </div>
                {/* <div className="w-full md:w-[90%] m-auto">
                    <span className="text-base text-zinc-400 font-semibold">
                        Our experts with over 19 years experience will repair your window in a professional and timely manner.
                    </span>
                </div>
                <div className="pt-5 w-full md:w-[90%] m-auto">
                    <span className="text-base text-zinc-400 font-semibold">
                        Our team at Avondale Auto Glass is committed to upholding our porpuse to make a difference with real care for our
                        customers. We do this by consistently providing our customers with our superior service developing auto glass
                        "Installation and window tint by providing ceramic glass film technology and ensuring every job across the company
                        is carried our with the same standards".
                    </span>
                </div> */}
                <div className="pt-5 w-full md:w-[90%] m-auto">
                    <div className='mb-2'>
                        <span className="text-[20px] text-ag-secondary-light font-black italic">Avondale Auto Glass Privacy Policy</span>
                    </div>
                    <div className='mb-8'>
                        <span className="text-[20px] text-ag-secondary-light font-normal">At Avondale Auto Glass, we respect and value your privacy. This Privacy Policy describes how we collect, use, and protect the personal information you provide to us through our website and related services. </span>
                    </div>
                    <div className='mb-2'>
                        <span className="text-[20px] text-ag-secondary-light font-black italic">Collection of Information</span>
                    </div>
                    <div className='mb-8'>
                        <span className="text-[20px] text-ag-secondary-light font-normal">When you use our website or interact with us, we may collect certain personal information such as your name, email address, phone number, and postal address. We may also collect information about your vehicle and service preferences.</span>
                    </div>
                    <div className='mb-2'>
                        <span className="text-[20px] text-ag-secondary-light font-black italic">Use of Information</span>
                    </div>
                    <div className='mb-8'>
                        <span className="text-[20px] text-ag-secondary-light font-normal">We use the information collected to provide you with our services, respond to your inquiries, process your requests, and enhance your experience with us.
                            Additionally, we may use your information to send you communications related to our services, promotions, or special events, provided you have consented to receive them.
                        </span>
                    </div>
                    <div className='mb-2'>
                        <span className="text-[20px] text-ag-secondary-light font-black italic">Protection of Information</span>
                    </div>
                    <div className='mb-8'>
                        <span className="text-[20px] text-ag-secondary-light font-normal">We value the trust you place in us by providing us with your personal information, so we take measures to appropriately protect it. We implement physical, electronic, and administrative security measures to safeguard your data against unauthorized access, disclosure, misuse, or alteration. Disclosure of Information We do not sell, rent, or share your personal information with third parties except when necessary to provide you with our services or to comply with applicable laws.</span>
                    </div>
                    <div className='mb-2'>
                        <span className="text-[20px] text-ag-secondary-light font-black italic">Access and Control of Information</span>
                    </div>
                    <div className='mb-8'>
                        <span className="text-[20px] text-ag-secondary-light font-normal">You may access, correct, or delete your personal information at any time by emailing us at [contact email]. You may also opt out of receiving future promotional communications by following the opt-out instructions included in such communications.</span>
                    </div>
                    <div className='mb-2'>
                        <span className="text-[20px] text-ag-secondary-light font-black italic">Changes to Privacy Policy</span>
                    </div>
                    <div className='mb-8'>
                        <span className="text-[20px] text-ag-secondary-light font-normal">We reserve the right to update or modify this Privacy Policy at any time. We will notify you of significant changes through a notice on our website or via email before they take effect. Contact If you have any questions or concerns about our Privacy Policy or privacy practices, please feel free to contact us at avondaleautoglass1@gmail.com. Last Updated: May 13, 2024</span>
                    </div>
                </div>
                <p>


                </p>
            </div>
        </>
    )
}

export default PrivacyNoticeInfo