import { useContext } from "react";
import { toast } from "react-toastify";
import { alertOptions, getAllCategories, getAllFeaturesWC } from "../../../../utils/Utilities";
import { searchProductEspecification } from "../../../../services/Product";
import { PermitsContext } from "../../../../services/Permits";
import { useNavigate } from "react-router-dom";
import QuoteByPhoto from "../QuoteByPhoto";
import QuoteByPowerWindowRepair from "../QuoteByPowerWindowRepair";
import QuoteByWindowTint from "../QuoteByWindowTint";
import BaseCar from "../../../../components/car/BaseCar";
import FrontVentLeft from "../../../../components/car/FrontVentLeft";
import RearVentLeft from "../../../../components/car/RearVentLeft";
import FrontDoorLeft from "../../../../components/car/FrontDoorLeft";
import RearDoorLeft from "../../../../components/car/RearDoorLeft";
import MiddleDoorLeft from "../../../../components/car/MiddleDoorLeft";
import RearQuarterRight from "../../../../components/car/RearQuarterRight";
import FrontVentRight from "../../../../components/car/FrontVentRight";
import RearVentRight from "../../../../components/car/RearVentRight";
import FrontDoorRight from "../../../../components/car/FrontDoorRight";
import RearDoorRight from "../../../../components/car/RearDoorRight";
import MiddleDoorRight from "../../../../components/car/MiddleDoorRight";
import FrontQuarterRight from "../../../../components/car/FrontQuarterRight";
import FrontQuarterLeft from "../../../../components/car/FrontQuarterLeft";
import Roof from "../../../../components/car/Roof";
import BackGlass from "../../../../components/car/BackGlass";
import Windshield from "../../../../components/car/Windshield";
import RearQuarterLeft from "../../../../components/car/RearQuarterLeft";
import { useMemo } from "react";
import AdditionalServices from "../step_three/AdditionalServices";

const StepTwoForm = ({ type, setShowModalRequest, handleModalWindowTint, handleModalReparation }) => {
    const navigate = useNavigate();
    const { searchData, setSearchData, typexOptions, setTypexOptions, setDistFeatures, setProduOptions, setProduOptionsUniv, glassSelected, setGlassSelected } = useContext(PermitsContext);


    // List of available glasses

    const { availableCarGlasses, availableCarGlassesObjects } = useMemo(() => {
        return typexOptions.reduce((availableGlasses, glass) => {

            if (glass.title === "Windshield" && glass.show) {
                availableGlasses.availableCarGlasses.push(glass.title)
                availableGlasses.availableCarGlassesObjects[glass.title] = {
                    glassType: glass,
                    carSide: glass
                }
            }
            // checks if glass is windshield or backglass if it is and show is true add it to the list
            //  if glass is not windshield or backglass checks what types of glasses are available

            glass.sons.forEach((glassSon) => {
                if (glassSon.show) {
                    availableGlasses.availableCarGlasses.push(glassSon.title + " " + glass.title)
                    availableGlasses.availableCarGlassesObjects[glassSon.title + " " + glass.title] = {
                        glassType: glassSon,
                        carSide: glass
                    }
                }
            })

            return availableGlasses
        }, { availableCarGlasses: [], availableCarGlassesObjects: {} })
    }, [typexOptions])

    const handleSelectGlass = (carSide, glassType) => {
        if (carSide.title === "Windshield") {
            let newTypeXOptions = typexOptions.map(element => {
                if (element.title === carSide.title) return { ...element, expand: true };
                return { ...element, expand: false };
            });
            setTypexOptions(newTypeXOptions);
        } else if (glassSelected !== glassType.title + " " + carSide.title) {
            let newTypeXOptions = typexOptions.map(element => {
                if (element.title === carSide.title) return { ...element, expand: true };
                return { ...element, expand: false };
            });

            newTypeXOptions = newTypeXOptions.map(element => {
                const newSons = element.sons.map(elementAux => {
                    if (elementAux.title === glassType.title && elementAux.value === glassType.value) {
                        if (glassType.title === "Replace my windshield") {
                            setSearchData({ ...searchData, typeGlass: elementAux.value, typeService: "REPLACEMENT" });
                        } else if (glassType.title === "Repair my windshield") {
                            setSearchData({ ...searchData, typeGlass: elementAux.value, typeService: "REPAIR" });
                        } else {
                            setSearchData({ ...searchData, typeGlass: elementAux.value, typeService: "REPLACEMENT" });
                        }
                        setGlassSelected(elementAux.title + " " + element.title)
                        return { ...elementAux, selected: true };
                    }
                    return { ...elementAux, selected: false };
                })
                return { ...element, sons: newSons };
            });

            setTypexOptions(newTypeXOptions);

        }
    }

    const searchProducts = () => {
        setSearchData({ ...searchData, nag: "" });
        const featuresAux = getAllFeaturesWC();
        const categoriesAux = getAllCategories();

        const paramsObj = {
            brand: searchData.brand,
            model: searchData.model,
            year: searchData.year,
            type_car: searchData.style,
            type_glass: searchData.typeGlass
        }
        return searchProductEspecification(paramsObj).then(res => {
            if (res.status === 200) {
                let distinctFeatures = [];
                const dataFilter = res.data.filter(element => {
                    if (
                        !String(element.ad_info_a).toLowerCase().includes(String("Export Only").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Mexico Only").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Canada Only").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Right-Hand-Drive").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Right-Hand Drive").toLowerCase()) &&
                        !String(element.ad_info_a).toLowerCase().includes(String("Right Hand Drive").toLowerCase())
                    ) {
                        return element;
                    }
                }).map(prodAux => {
                    const newCategories = categoriesAux.map(catAux => {
                        let newFeaturesAux = [];
                        const featuresFilters = featuresAux.filter(featAux => featAux.category === catAux.label);
                        for (let i = 0; i < featuresFilters.length; i++) {
                            if (
                                (String(prodAux.ad_info_a).toLowerCase().includes(String(featuresFilters[i].value).toLowerCase()) === true) ||
                                (prodAux.antena === "Y" && featuresFilters[i].value === "Antenna")
                            ) {
                                const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem.value === featuresFilters[i].value);;
                                if (!foundFeatures) distinctFeatures.push(featuresFilters[i]);
                                newFeaturesAux.push(featuresFilters[i]);
                            } else if (
                                prodAux.hud === "Y" && (featuresFilters[i].value === "HUD (Head up display)" ||
                                    featuresFilters[i].value === "W/HUD" || featuresFilters[i].value === "Augmented Reality HUD" ||
                                    featuresFilters[i].value === "HUD W/Augmented Reality Navigation")
                            ) {
                                const foundFeatures = distinctFeatures.find(distFeatElem => distFeatElem.value === "HUD");;
                                if (!foundFeatures) distinctFeatures.push({ label: "HUD", value: "HUD", category: "Calibration", status: false });
                                newFeaturesAux.push(featuresFilters[i]);
                            }
                        }

                        if (newFeaturesAux.length > 0) return { ...catAux, features: newFeaturesAux, status: true }
                        return catAux;
                    });
                    return { ...prodAux, category: newCategories }
                });

                setDistFeatures(distinctFeatures);

                if (dataFilter.length <= 5) {
                    setProduOptions(dataFilter);
                }

                setProduOptionsUniv(dataFilter);

                return dataFilter;
            } else {
                return [];
            }
        }).catch(() => {
            return [];
        });
    }

    const handleStep = async () => {
        if (searchData.typeGlass !== "") {
            const resProducts = await searchProducts();
            if (resProducts.length > 0) {
                navigate(type === "free" ? "/myQuotes/step_three" : "/home/step_three");
            } else {
                toast.warning("No glasses were found with the entered characteristics", alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    const handleBack = () => {
        const newTypexOptions = typexOptions.map(elem => {
            const newSons = elem.sons.map(elemSons => ({ ...elemSons, selected: false }));
            return { ...elem, expand: false, sons: newSons };
        });

        setGlassSelected("")
        setSearchData({
            ...searchData,
            typeGlass: "",
            windshieldVisorStrip: false,
            ceramicFull70: false,
            ceramicFull90: false,
            waterTreatment: false
        });

        setTypexOptions(newTypexOptions);
        navigate(type === "free" ? "/myQuotes" : "/home");
    }

    const handleType = (typeElement) => {
        const newTypeXOptions = typexOptions.map(element => {
            if (element.title === typeElement.title) return { ...element, expand: !element.expand };
            return { ...element, expand: false };
        });
        setTypexOptions(newTypeXOptions);
    }

    const handleTypeSub = (typeElement) => {
        const newTypeXOptions = typexOptions.map(element => {
            const newSons = element.sons.map(elementAux => {
                if (elementAux.title === typeElement.title && elementAux.value === typeElement.value) {
                    if (typeElement.title === "Replace my windshield") {
                        setSearchData({ ...searchData, typeGlass: elementAux.value, typeService: "REPLACEMENT" });
                    } else if (typeElement.title === "Repair my windshield") {
                        setSearchData({ ...searchData, typeGlass: elementAux.value, typeService: "REPAIR" });
                    } else {
                        setSearchData({ ...searchData, typeGlass: elementAux.value, typeService: "REPLACEMENT" });
                    }
                    setGlassSelected(elementAux.title + " " + element.title)
                    return { ...elementAux, selected: true };
                }
                return { ...elementAux, selected: false };
            })
            return { ...element, sons: newSons };
        });
        setTypexOptions(newTypeXOptions);
    }

    return (
        <>
            <div className="flex justify-center items-center space-x-5 pb-5">
                <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">
                    What do you need fixed?
                </h1>
            </div>

            <div className="grid md:flex md:justify-between md:items-start md:space-x-8">
                <div className="w-auto md:w-[83%]">
                    <div className="flex flex-col lg:flex-row justify-between border-t-2 border-ag-primary py-10 gap-4">
                        <div className="w-full lg:w-1/2 space-y-3">
                            {
                                typexOptions.map((element, i) => {
                                    if (element.show) {
                                        return (
                                            <div className={`rounded-xl ${element.expand ? "border border-red-500" : ""}`} key={i}>
                                                <div className={`flex justify-between items-center rounded-xl px-10 py-4 cursor-pointer hover:bg-red-100 ${element.expand ? "bg-red-100 border-b border-red-500" : "border border-ag-secondary-light"}`} onClick={() => handleType(element)}>
                                                    <div>
                                                        <span className="text-ag-secondary-letter">
                                                            {element.title}
                                                        </span>
                                                    </div>
                                                    {/* <div className="w-40">
                                                    <img className="m-auto" src={element.image} />
                                                </div> */}
                                                </div>
                                                {
                                                    element.expand ?
                                                        <div className="space-y-3 px-8 py-3">
                                                            {element.sons.map((elementAux, j) => {
                                                                if (elementAux.show) {
                                                                    return (
                                                                        <button
                                                                            className={`w-full px-5 py-3 text-ag-secondary-letter rounded-xl hover:bg-zinc-200 hover:border-blue-600 ${elementAux.selected ? "border-2 bg-zinc-200 border-blue-600" : " border border-ag-secondary"}`}
                                                                            key={j}
                                                                            type="button"
                                                                            onClick={() => handleTypeSub(elementAux)}
                                                                        >
                                                                            <div className="flex justify-between items-center">
                                                                                <div>
                                                                                    <span className="text-ag-secondary-letter">
                                                                                        {elementAux.title}
                                                                                    </span>
                                                                                </div>
                                                                                {/* {
                                                                                element.title !== "Windshield" ?
                                                                                    <div className="w-40">
                                                                                        <img className="m-auto" src={elementAux.image} />
                                                                                    </div>
                                                                                : null
                                                                            } */}
                                                                        </div>
                                                                    </button>
                                                                )
                                                                }
                                                            })}
                                                        </div>
                                                    : null
                                                }
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>

                        <div className="w-full lg:w-1/2 flex justify-center">
                            <BaseCar>
                                <Windshield 
                                    isSelected={glassSelected === "Replace my windshield Windshield" || glassSelected === "Repair my windshield Windshield"} 
                                    isAvailable={availableCarGlasses.includes("Replace my windshield Windshield") || availableCarGlasses.includes("Repair my windshield Windshield")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Windshield"]?.carSide, availableCarGlassesObjects["Windshield"].glassType) }
                                />

                                <FrontQuarterLeft isSelected={glassSelected === "dd"} isAvailable={availableCarGlasses.includes("dd")} />
                                
                                <FrontVentLeft 
                                    isSelected={glassSelected === "Front Vent Left Side"} 
                                    isAvailable={availableCarGlasses.includes("Front Vent Left Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Front Vent Left Side"]?.carSide, availableCarGlassesObjects["Front Vent Left Side"].glassType) }
                                />

                                <FrontDoorLeft 
                                    isSelected={glassSelected === "Front Door Left Side"} 
                                    isAvailable={availableCarGlasses.includes("Front Door Left Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Front Door Left Side"]?.carSide, availableCarGlassesObjects["Front Door Left Side"].glassType)}
                                />

                                <MiddleDoorLeft isSelected={glassSelected === "dd"} isAvailable={availableCarGlasses.includes("dd")}/>
                                
                                <RearDoorLeft 
                                    isSelected={glassSelected === "Rear Door Left Side"} 
                                    isAvailable={availableCarGlasses.includes("Rear Door Left Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Rear Door Left Side"]?.carSide, availableCarGlassesObjects["Rear Door Left Side"].glassType)}
                                />

                                <RearVentLeft 
                                    isSelected={glassSelected === "Rear Vent Left Side"} 
                                    isAvailable={availableCarGlasses.includes("Rear Vent Left Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Rear Vent Left Side"]?.carSide, availableCarGlassesObjects["Rear Vent Left Side"].glassType)}
                                />

                                <RearQuarterLeft 
                                    isSelected={glassSelected === "Rear Quater Left Side"} 
                                    isAvailable={availableCarGlasses.includes("Rear Quater Left Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Rear Quater Left Side"]?.carSide, availableCarGlassesObjects["Rear Quater Left Side"].glassType)}
                                />

                                <Roof />

                                <FrontQuarterRight
                                    isSelected={glassSelected === "dd"}
                                    isAvailable={availableCarGlasses.includes("dd")}
                                />

                                <FrontVentRight 
                                    isSelected={glassSelected === "Front Vent Right Side"} 
                                    isAvailable={availableCarGlasses.includes("Front Vent Right Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Front Vent Right Side"]?.carSide, availableCarGlassesObjects["Front Vent Right Side"].glassType)}
                                />

                                <FrontDoorRight 
                                    isSelected={glassSelected === "Front Door Right Side"} 
                                    isAvailable={availableCarGlasses.includes("Front Door Right Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Front Door Right Side"]?.carSide, availableCarGlassesObjects["Front Door Right Side"].glassType)}
                                />

                                <MiddleDoorRight 
                                    isSelected={glassSelected === "dd"} 
                                    isAvailable={availableCarGlasses.includes("dd")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["dd"]?.carSide, availableCarGlassesObjects["dd"].glassType)}
                                />

                                <RearDoorRight 
                                    isSelected={glassSelected === "Rear Door Right Side"} 
                                    isAvailable={availableCarGlasses.includes("Rear Door Right Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Rear Door Right Side"]?.carSide, availableCarGlassesObjects["Rear Door Right Side"].glassType)}
                                />

                                <RearVentRight 
                                    isSelected={glassSelected === "Rear Vent Right Side"} 
                                    isAvailable={availableCarGlasses.includes("Rear Vent Right Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Rear Vent Right Side"]?.carSide, availableCarGlassesObjects["Rear Vent Right Side"].glassType)}
                                />

                                <RearQuarterRight 
                                    isSelected={glassSelected === "Rear Quater Right Side"} 
                                    isAvailable={availableCarGlasses.includes("Rear Quater Right Side")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Rear Quater Right Side"]?.carSide, availableCarGlassesObjects["Rear Quater Right Side"].glassType)}
                                />

                                <BackGlass 
                                    isSelected={glassSelected === "Backglass Backglass"} 
                                    isAvailable={availableCarGlasses.includes("Backglass Backglass")}
                                    onClick={() => handleSelectGlass(availableCarGlassesObjects["Backglass Backglass"]?.carSide, availableCarGlassesObjects["Backglass Backglass"].glassType)}
                                />
                            </BaseCar>
                        </div>
                    </div>
                    
                    <div className="mt-10 py-10 border-t-2 border-ag-primary space-y-3">
                        <div className="flex justify-between items-center">
                            <button
                                className="w-full lg:w-[30%] bg-transparent text-ag-secondary-letter px-3 py-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => handleBack()}
                            >
                                Back
                            </button>

                            {/* <button
                                className="w-full lg:w-[30%] bg-ag-primary-light text-white px-3 py-2 rounded-xl disabled:bg-zinc-200"
                                type="button"
                                onClick={() => handleStep()}
                            >
                                Continue
                            </button> */}
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-auto pb-10 md:pb-0 space-y-5">
                    <div className="w-full md:w-auto pb-10 md:pb-0 space-y-5">
                            <div>
                                <button
                                    className="font-bold bg-[#ff5f52] hover:bg-[#d72b1d] h-[50px] text-white w-full py-1 border border-ag-primary-light text-sm rounded-xl"
                                    type="button"
                                    onClick={() => handleBack()}
                                >
                                    Auto Glass Service Quote
                                </button>
                            </div>
                    </div>

                    <QuoteByPhoto setShowModalRequest={setShowModalRequest} />
                    <QuoteByWindowTint handleModalWindowTint={handleModalWindowTint} />
                    <QuoteByPowerWindowRepair handleModalReparation={handleModalReparation} />
                </div>
            </div>
        </>
    );
}

export default StepTwoForm;